import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

function Login() {
  const navigate = useNavigate();

  const handleLogin = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        const email = user.email;
        // const serializedUser = JSON.stringify(user);
        localStorage.setItem("user_id", user.uid);
        localStorage.setItem("authToken", token);
        localStorage.setItem("userEmail", email);
        console.log(token);
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("user_id");
        localStorage.removeItem("authToken");
      });
  };

  return (
    <div className="flex items-center justify-center w-full h-full min-h-full login">
      <div>
        <div className="px-3 mb-3 text-3xl text-white sm:text-4xl md:text-5xl lg:text-6xl primary-font">
          Welcome to The Lawbook!
        </div>
        <div className="mb-5 text-center text-white">
          Your Intelligent Legal Companion
        </div>
        <div className="text-center">
          <button
            type="submit"
            className={"py-3 px-6 grad-btn rounded text-white "}
            onClick={() => handleLogin()}
          >
            <div className="flex items-center">
              <GoogleIcon className="mr-2" /> Continue with Google
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
