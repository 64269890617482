export default async function AskQueryApi(query, user_id, metadata) {
  let res = "";
  try {
    const body = {
      query: query,
      user_id: user_id,
      metadata: metadata,
    };

    const token = "Bearer " + localStorage.getItem("authToken");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    };
    console.log("Sending request to:", `${process.env.REACT_APP_API_URL}ask`);
    await fetch(`${process.env.REACT_APP_API_URL}ask`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((response) => {
        console.log("Received response:", response);
        res = response;
      })
      .catch((error) => {
        console.error("Error ask query: ", error);
        throw error;
      });
  } catch (error) {
    console.error(error);
    throw error;
  }

  return res;
}
