import App from "../views/App";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "../views/Login";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

function Main() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthStateChanged = (user) => {
      if (user) {
        console.log("User is authenticated");
        navigate("/");
        setLoading(false);
      } else {
        console.log("User is not authenticated");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/login");
        setLoading(false);
      }
    };
  
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);
    return () => unsubscribe();
  }, [navigate]);

  return (
    !loading && (
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    )
  );
}

export default Main;
