// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCCMPRgdzBrvt--VuhRlc1mgjLQkUvGaP0",
  authDomain: "thelawbook-9f928.firebaseapp.com",
  projectId: "thelawbook-9f928",
  storageBucket: "thelawbook-9f928.appspot.com",
  messagingSenderId: "127726787354",
  appId: "1:127726787354:web:b2fa2499907d9457a02aa3",
  measurementId: "G-D9CV1NTKXJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default app;
export { auth };
