import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import AskQueryApi from "../api/AskQueryApi";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import { useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import NotificationHelper from "../Notification";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import Navbar from "../components/Navbar";
import upload from "../assets/images/upload.png";
import example from "../assets/images/example.svg";
import file from "../assets/images/file.svg";
import lock from "../assets/images/lock.svg";
import CreateContainerApi from "../api/CreateContainerApi";
import TrainApi from "../api/TrainApi";
import CreateIndexApi from "../api/CreateIndexApi";

function App() {
  const [queryError, setQueryError] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [dragOver, setDragOver] = useState(false);
  const [openResultUi, setOpenResultUi] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [result, setResult] = useState([]);
  const [files, setFiles] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  // const [fileName, setFileName] = useState("");
  const email = localStorage.getItem("userEmail");
  // const email = JSON.parse(localData)?.email;

  async function handleFileUpload(event) {
    setUploadLoading(true);
    const file = event.target.files[0];

    // Check if the file is a PDF
    if (file.type !== "application/pdf") {
      setNotification({
        open: true,
        type: "error",
        message: "Error: Only PDF files are allowed.",
      });
      setUploadLoading(false);
      return;
    }

    // Check if the file size is below 100MB (100 * 1024 * 1024 bytes)
    if (file.size > 100 * 1024 * 1024) {
      setNotification({
        open: true,
        type: "error",
        message: " File size exceeds 100MB limit.",
      });
      setUploadLoading(false);
      return;
    }
    const accountName = "lawbook"; // Replace with your Azure Blob Storage account name

    // upload file
    const apiResponse = await CreateContainerApi(email);
    console.log("API Response:", apiResponse);

    if (!apiResponse || !apiResponse.user_id || !apiResponse.token) {
      setNotification({
        open: true,
        type: "success",
        message: "File Upload Success!",
      });
      setUploadLoading(false);
      return;
    }

    localStorage.setItem("user_id", apiResponse.user_id);

    const blobService = new BlobServiceClient(
      `https://${accountName}.blob.core.windows.net/?${apiResponse.token}`
    );

    // get Container - full public read access
    const containerClient = blobService.getContainerClient(apiResponse.user_id);
    const blobClient = containerClient.getBlockBlobClient(file.name);

    try {
      const responses = await blobClient.uploadData(file);
      await TrainApi(apiResponse.user_id);
      setNotification({
        open: true,
        type: "success",
        message: "File uploaded successfully!",
      });
      setUploadLoading(false);
      setIsFileUploaded(true);
      setFiles(file.name);
      const user_email = localStorage.getItem("userEmail");
      const createIndexResponse = await CreateIndexApi(user_email);
      console.log("CreateIndexApi Response:", createIndexResponse);
      return responses;
    } catch (error) {
      console.error("Upload error:", error);
      setNotification({
        open: true,
        type: "error",
        message: "Error uploading file.",
      });
      setUploadLoading(false);
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    const files = event.dataTransfer.files;
    const dropEvent = { target: { files } };
    handleFileUpload(dropEvent);
  };

  const STATIC_FILENAME = "";

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);
    if (!data.get("query")) {
      setQueryError(true);
      setSubmit(false);
      setLoading(false);
      return;
    }

    try {
      const apiResponse = await AskQueryApi(
        data.get("query"),
        localStorage.getItem("user_id"),
        [STATIC_FILENAME]
      );

      if (apiResponse.statusCode) {
        setQueryError(apiResponse.message);
      } else if (apiResponse) {
        setOpenResultUi(true);

        // Parse the response from the response
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(
          apiResponse.response,
          "text/html"
        );
        const listItems = htmlDoc.querySelectorAll("li");

        let messageArray;
        if (listItems.length > 0) {
          // If there are 'li' elements, extract their text content
          messageArray = Array.from(listItems).map((li) => li.textContent);
        } else {
          // If there are no 'li' elements, treat the message as a plain text string
          messageArray = [apiResponse.response];
        }

        setResult([...result, { q: data.get("query"), a: messageArray }]);
        if (apiResponse.status === 200) {
          const user_email = localStorage.getItem("userEmail");
          const createIndexResponse = await CreateIndexApi(user_email);
          console.log("CreateIndexApi Response:", createIndexResponse);
        }
      }
    } catch (error) {
      console.error("Error handling submit: ", error);
      setQueryError("An error occurred while processing your request.");
    }

    setLoading(false);
  }

  return (
    <>
      <Box>
        <Box>
          <Navbar />
          <div className="flex overflow-hidden full--height">
            <div className="min-w-[240px] max-w-[240px] bg-[#1F1F1F]">
              {isFileUploaded ? (
                <div className="px-3 py-5 text-[#d0d0d0] ">
                  <div>The document that is being referenced</div>
                  <div className="flex items-center gap-2 mt-5 w-[216px] truncate">
                    <img src={file} alt="file" />
                    <p className="text-sm "> {files}</p>
                  </div>
                  <div className="mt-5">
                    {uploadLoading ? (
                      <div className="flex justify-center">
                        <CircularProgress
                          size={25}
                          sx={{
                            color: "white",
                            position: "relative",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                      </div>
                    ) : (
                      <Button
                        className={"py-3 px-6 w-full grad-btn rounded   "}
                        component="label"
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          paddingBlock: "12px",
                        }}
                      >
                        Change
                        <input type="file" hidden onChange={handleFileUpload} />
                      </Button>
                    )}
                  </div>
                </div>
              ) : (
                <Stack
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  sx={{ mt: 2 }}
                >
                  <Box
                    className={`upload-area ${dragOver ? "drag-over" : ""}`}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "8px",
                      p: 2,
                      border: "2px dashed #aaaaaa",
                      borderRadius: "8px",
                      textAlign: "center",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                    }}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <img
                      src={upload}
                      alt="upload"
                      className="-mb-1 text-white"
                    />
                    <div className="text-neutral-400">
                      Drag and drop files here
                    </div>

                    <Divider
                      sx={{
                        "&::before, &::after": {
                          borderColor: "#9e9e9e",
                          width: "60px",
                        },
                      }}
                      className="text-neutral-400"
                    >
                      or
                    </Divider>

                    <div>
                      {uploadLoading ? (
                        <CircularProgress
                          size={25}
                          sx={{
                            color: "white",
                            position: "relative",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                      ) : (
                        <Button
                          component="label"
                          sx={{ textTransform: "none" }}
                        >
                          Browse files
                          <input
                            type="file"
                            hidden
                            onChange={handleFileUpload}
                          />
                        </Button>
                      )}
                    </div>

                    <Typography variant="body2" color="red">
                      Maximum file size is 100MB
                    </Typography>
                  </Box>
                </Stack>
              )}
            </div>
            <div className="relative w-full overflow-y-hidden full--height">
              <div>
                <div className="px-5 pb-32 overflow-y-scroll full--height">
                  {openResultUi ? (
                    <div className="max-w-4xl mx-auto mt-10 text-sm ">
                      <div>
                        {result?.map((data, index) => (
                          <div key={index}>
                            <div className="bg-[#1F1F1F] p-3 rounded  overflow-auto text-[#d0d0d0]">
                              {data.q}
                            </div>
                            <div
                              className="p-3 overflow-auto text-[#d0d0d0]"
                              dangerouslySetInnerHTML={{
                                __html: data.a,
                              }}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="text-center max-w-4xl mx-auto text-sm mt-10 text-[#d0d0d0]">
                        Welcome to the LawBook Demo! Explore the power of our
                        intelligent legal research platform. Upload a relevant
                        document and enter your search query below to experience
                        the seamless integration of advanced AI technology with
                        the legal domain. Get ready to uncover precise insights
                        and answers in a natural language format.
                      </div>

                      <div className="grid grid-cols-3 gap-4 max-w-4xl mx-auto text-center text-[#d0d0d0] mt-10">
                        <div>
                          <div className="mb-5">
                            <img
                              src={example}
                              alt="example"
                              className="mx-auto mb-2 "
                            />
                            <div className="text-base tracking-wide text-[#d0d0d0]">
                              Examples
                            </div>
                          </div>
                          <div className="bg-[#1F1F1F]  p-3 text-sm rounded  min-h-[112px] flex items-center justify-center">
                            "Give me a summary of the case"
                          </div>
                          <div className="bg-[#1F1F1F]   p-3 mt-5 text-sm  rounded  min-h-[112px] flex items-center justify-center">
                            <p>
                              Download free cases from{" "}
                              <a
                                href="https://upviewtechnologies-my.sharepoint.com/personal/yadurshan_upview_tech/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fyadurshan%5Fupview%5Ftech%2FDocuments%2FPersonal%20%2D%20Upview%2FThe%20Lawbook%2FPDF%20Templates&ct=1689572769607&or=Teams%2DHL&ga=1"
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-500"
                              >
                                PDF Templates
                              </a>{" "}
                              and upload to try out the platform.
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="mb-5">
                            <img
                              src={file}
                              alt="Capabilities"
                              className="mx-auto mb-2"
                            />
                            <div className="text-base tracking-wide text-[#d0d0d0]">
                              Capabilities
                            </div>
                          </div>
                          <div className="bg-[#1F1F1F] p-3 text-sm rounded min-h-[112px] flex items-center justify-center">
                            You can search within documents, ask questions, and
                            obtain accurate answers in natural, human-like
                            language.
                          </div>
                          <div className="bg-[#1F1F1F] p-3 mt-5 text-sm rounded min-h-[112px] flex items-center justify-center">
                            You can expect LawBook to provide accurate and
                            relevant answers to the best of its capabilities.
                          </div>
                        </div>
                        <div>
                          <div className="mb-5">
                            <img
                              src={lock}
                              alt="Limitations"
                              className="mx-auto mb-2"
                            />
                            <div className="text-base tracking-wide text-[#d0d0d0]">
                              Limitations
                            </div>
                          </div>
                          <div className="bg-[#1F1F1F] p-3 text-sm rounded  min-h-[112px] flex items-center justify-center">
                            The demo site is limited to the legal documents you
                            upload.
                          </div>
                          <div className="bg-[#1F1F1F] p-3 mt-5 text-sm rounded  min-h-[112px] flex items-center justify-center">
                            Features in the demo are representative of LawBook's
                            capabilities but may not encompass all the available
                            features.
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="absolute bottom-0 w-full px-5 pt-2 bg-black right-2">
                <Box
                  className="max-w-4xl mx-auto translate-x-1"
                  component="form"
                  onSubmit={handleSubmit}
                >
                  <div className="flex items-center justify-between mb-2">
                    <div className=" text-sm text-[#d0d0d0]">
                      Enter your legal query here
                    </div>
                    {result?.length > 0 && (
                      <div
                        className="text-sm text-blue-500 cursor-pointer "
                        onClick={() => setResult([])}
                      >
                        Clear chat
                      </div>
                    )}
                  </div>

                  <div className="flex">
                    <input
                      name="query"
                      value={query}
                      onChange={(event) => {
                        setQuery(event.target.value);
                        setQueryError("");
                      }}
                      className="rounded-l bg-[#1F1F1F] text-base p-3 w-full focus:outline-0 text-[#d0d0d0]"
                      placeholder="Enter your query..."
                    />
                    <button
                      type="submit"
                      disabled={loading || submit}
                      className={
                        " grad-btn min-w-[56px] flex items-center justify-center rounded-r " +
                        (!loading && "hover-btn")
                      }
                    >
                      {loading ? (
                        <HourglassEmptyOutlinedIcon className="rotate" />
                      ) : (
                        <ArrowForwardIosOutlined />
                      )}
                    </button>
                  </div>

                  {queryError ? (
                    <FormHelperText sx={{ ml: 1, color: "red" }}>
                      Query can't be empty!
                    </FormHelperText>
                  ) : (
                    <FormHelperText sx={{ ml: 1 }}> </FormHelperText>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </Box>
      </Box>
      <NotificationHelper notification={notification} />
    </>
  );
}

export default App;
