import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

export default function NotificationHelper({ notification }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(notification.open);
  }, [notification]);

  function handleClose() {
    setOpen(false);
  }
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Alert
        //variant="filled"
        onClose={handleClose}
        severity={notification?.type}
        sx={{ width: "100%" }}
      >
        {notification?.message}
      </Alert>
    </Snackbar>
  );
}
