import { Avatar } from "@mui/material";
import { useState } from "react";
import lawBook from "../../src/assets/images/lawBook-logo.png";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";

function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAbout = () => {
    window.open("https://thelawbook.online/", "_blank");
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    auth
      .signOut()
      .then(() => {
        localStorage.removeItem("user");
        localStorage.removeItem("user_id");
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  return (
    <>
      <div className="flex justify-between items-center py-3 bg-[#111] px-3">
        <a href="https://thelawbook.online/" target="_blank" rel="noreferrer">
          <img className="h-10" src={lawBook} alt="LawBook logo" />
        </a>
        <div className="flex items-center">
          <div>
            <Avatar
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ bgcolor: "#ccc", width: 40, height: 40 }}
              className="cursor-pointer"
              alt="User"
              src={JSON.parse(localStorage.getItem("user"))?.photoURL}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div
                onClick={() => handleAbout()}
                className="w-32 py-2 px-3 cursor-pointer hover:bg-neutral-200"
              >
                About
              </div>
              <div
                onClick={() => handleLogout()}
                className="w-32 py-2 px-3 cursor-pointer hover:bg-neutral-200"
              >
                Logout
              </div>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
